@import "variables";
@import "macros";

body {
  background: #272727;
  color: whitesmoke;
}

.app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  padding: 0;
  margin: 0;
  display: inherit;
  @include centerContent;

  svg {
    display: inline-block;
    width: 1.25rem !important;
    height: 1.25rem;
    padding: 0;
    margin: 0;
    stroke: currentColor;
    stroke-width: 1.5;
  }
}

.tools {
  /*background: black;*/
  padding: 1rem;
  padding-top: 0.5rem;
  text-align: center;
}

.tool {
  color: #f8f8f8;
  width: 3rem !important;
  height: 3rem !important;
  font-size: 1.5rem;
  background: #272727;
  border-radius: 2rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  display: inline-flex !important;
  @include centerContent;

  svg {
    width: 1.5rem !important;
    height: 1.5rem;
    padding: 0;
    margin: 0;
    /*stroke: currentColor;*/
    stroke-width: 1.5;
  }

  @include mediaMobile {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
    margin-left: 0;
    margin-right: 0;
  }

  &.-off {
    color: #919191;
  }

  &.-active,
  &:hover {
    color: white;
    background: #676767;
  }

  &:active,
  &:focus {
    color: #cccccc;
    background: black;
  }
}

.tool-close {
  background: #9d0000;

  &:hover {
    background: #f60000;
  }

  &:active,
  &:focus {
    color: white;
    background: #f60000;
  }
}

//.video {
//  transition: all 1s;
//}

.videos {
  padding: 0.25rem;
  padding-bottom: 0;

  flex-wrap: wrap;
  justify-content: center;

  .item {
    display: flex;
    padding: 0.25rem;
    position: relative;
    flex: auto;
    overflow: hidden;
  }

  .video {
    background: #444444;
    border-radius: 0.25rem;
    max-height: 100%;

    display: block;
    margin: 0;

    // Safari Hack
    position: absolute;
    top: calc(50%);
    left: calc(50%);
    width: calc(100% - 0.5rem) !important;
    height: calc(100% - 0.5rem) !important;
    transform: translateY(-50%) translateX(-50%);
    // max-height: calc(50vh - 3rem) !important;

    &[data-fit="cover"] {
      object-fit: cover;
    }

    &[data-fit="contain"] {
      object-fit: contain;
    }

    &.-mirrored {
      transform: translateY(-50%) translateX(-50%) scaleX(-1);
    }
  }

  .video-placeholder {
    min-height: 6rem;
    background: #333333;
    color: white;
    display: flex;

    &.-overlay {
      background: rgba(51, 51, 51, 0.2);
    }

    &.-info {
      label {
        position: absolute;
        bottom: $px-8;
        right: $px-8;
        border-radius: $px-4;
        padding: $px-4 $px-8;
        background: rgba(255, 255, 255, 0.5);
        color: #333333;
        font-size: 0.8rem;

        &:hover {
          background: rgba(255, 255, 255, 1);
          color: black !important;
          cursor: pointer;
        }
      }
      
      span {
        position: absolute;
        bottom: $px-8;
        right: $px-56;
        border-radius: $px-4;
        padding: $px-4 $px-8;
        background: rgba(255, 255, 255, 0.5);
        color: #333333;
        font-size: 0.8rem;
      
        &:hover {
          background: rgba(255, 255, 255, 1);
          color: black !important;
          cursor: pointer;
        }
      }

      .-long {
        display: block;
        text-align: left;
        left: $px-8;
        font-size: 1em;
        background: rgba(255, 255, 255, 0.9);
        padding: $px-16;
      }

      .-short {
        display: flex;
        align-items: center;
        font-family: $mono-font-family;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.9);

        svg {
          display: inline-block;
          width: 0.8rem !important;
          height: 0.8rem;
          padding: 0;
          margin: 0;
          margin-right: 0.4rem;
          // stroke: rgba(255, 255, 255, 0.5);
          stroke-width: 3;
        }
      }

      //&:hover {
      //  .-long {
      //    display: block;
      //  }
      //
      //  .-short {
      //    display: none;
      //  }
      //}
    }

    svg.-icon-placeholder {
      display: inline-block;
      padding: 0;
      margin: 0;
      stroke: currentColor;
      stroke-width: 1;
      text-align: center;
      width: 4rem !important;
      height: 4rem;
      color: white;
      animation: blink 1000ms infinite;
    }

    label {
      display: block;
      margin-top: 1rem;
    }
    
    &.-logo {
      label {
        position: absolute;
        top: 0.8rem;
        left: 0.8rem;
        border-radius: $px-4;
        padding: $px-4 $px-8;
        background: rgba(255, 255, 255, 0.5);
        color: #333333;
        font-size: 0.8rem;
        margin-top: 0;

        &:hover {
          background: rgba(255, 255, 255, 1);
          color: black !important;
          cursor: pointer;
        }
      }
      
      span {
        position: absolute;
        top: 0.8rem;
        left: $px-64;
        border-radius: $px-4;
        padding: $px-4 $px-8;
        background: rgba(255, 255, 255, 0.5);
        color: #333333;
        font-size: 0.8rem;
        margin-top: 0;
      
        &:hover {
          background: rgba(255, 255, 255, 1);
          color: black !important;
          cursor: pointer;
        }
      }

      .-long {
        display: block;
        text-align: left;
        left: $px-8;
        font-size: 1em;
        background: rgba(255, 255, 255, 0.9);
        padding: $px-16;
      }

      .-short {
        display: flex;
        align-items: center;
        font-family: $mono-font-family;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.9);

        svg {
          display: inline-block;
          width: 0.8rem !important;
          height: 0.8rem;
          padding: 0;
          margin: 0;
          margin-right: 0.4rem;
          // stroke: rgba(255, 255, 255, 0.5);
          stroke-width: 3;
        }
      }

      //&:hover {
      //  .-long {
      //    display: block;
      //  }
      //
      //  .-short {
      //    display: none;
      //  }
      //}
    }
  }

}

.video-fill {
}

[data-mode="default"] {
  @import "layout-default";
}

[data-mode="maximized"] {
  @import "layout-maximized";
}

.transform {
  display: none;

  &.debugTransform {
    display: block;

    #transformVideo {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
      height: 200px;
      z-index: 99999;
    }

    #transformOutput {
      display: block;
      position: absolute;
      top: 0;
      left: 200px;
      width: 200px;
      height: 200px;
      z-index: 99999;
    }
  }
}

.bandwidth {
  display: block;
  position: absolute;
  top: 1rem;
  color: red;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background: white;
  font-weight: bold;
  left: 1rem;
  //width: 200px;
  //height: 200px;
  z-index: 99999;
}

.message-container {
  display: flex;
  position: absolute;
  z-index: 9;
  left: 1rem;
  bottom: 1rem;
  right: 1rem;
  justify-content: center;

  .message {
    display: block;
    background: rgba(255, 255, 255, 0.85);
    padding: 1rem;
    color: black;
    border-radius: 0.25rem;
    max-width: 80%;
    min-width: calc(min(20rem, 80%));
    line-height: 1.5;

    @include mediaMobile {
      max-width: 100%;
      width: 100%;
    }

    svg {
      //display: inline;
      //float: none;
      //padding: 0;
      //margin: 0;
      //margin-left: 1em;
      //margin-right: 1em;
      width: 1em;
      height: 1em;
      stroke-width: 1.5;
      stroke: black;
    }

    u {
      cursor: pointer;
    }
  }

  &.-error .message {
    color: white;
    font-weight: bold;
    background-color: rgba(191, 0, 0, 0.85);
  }
}

.settings-group {
  padding: 0.5rem;
  border-radius: 0.25rem;
  background: #f0f0f0;
  margin-bottom: 0.5rem;

  .settings-info {
    margin-top: 0.5rem !important;
    line-height: 1.2rem;
    font-size: 0.9rem;

    img {
      display: block;
      margin-top: 0.5rem !important;
    }
  }
}

.release-info,
.release-info a {
  color: #666666;
  margin-top: $px-16;
  margin-bottom: $px-32;
  text-align: right;
  text-decoration: none;
  font-size: 0.8rem;
}

.panel {
  width: clamp(25rem, 25%, 50%);
  padding: 2rem;
  max-width: 50%;
  color: black;
  background: white;

  h1 {
    font-size: $font-size-lg;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }
}

// .footer {
//   @include mediaMobile {
//     font-size: smaller;
//   }
// }

.start-button {
  border-radius: 1rem !important;
  font-size: Max(4vh, 2rem) !important;
  font-weight: 600 !important;
}
